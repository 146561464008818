import React, {useState, useEffect} from 'react';
import axios from 'axios';

const BlogPosts = (props) => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [brand, setBrand] = useState("");

    useEffect(() => {
        let newBrand = "";

        switch(props.brand) {
            case "BMW i": newBrand = "BMWI"; break;
            case "BMW M": newBrand = "BMWM"; break;
            case "KTM X-Bow": newBrand = "KTMXBow"; break;
            case "Land Rover": newBrand = "LandRover"; break;
            default : newBrand = props.brand;
        }

        axios.get(`/wp-json/custom/v1/blogposts/${newBrand}/0`)
            .then(res => {
                setPosts(res.data.posts);
                setTotal(res.data.total);
                setPage(1);
                setBrand(newBrand);
            });
    },[props.brand]);

    const loadNewPosts = async () => {
        await axios.get(`/wp-json/custom/v1/blogposts/${brand}/${page}`)
            .then(res => {
                setPosts(posts.concat(res.data.posts));
                setPage(page+1);
            });
    };

    if(posts.length > 0) {
        return (
            <div className="row">
                {posts.map((post, index) => {
                    let disclaimers = post.verbrauchsangaben.replace(/<p>/g, "");
                    disclaimers = disclaimers.replace(/<\/p>/g, "");
                    disclaimers = disclaimers.split("[nl]");

                    return (
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3 blog-list">
                            <div className="uk-card uk-card-default">
                                <div style={{width: "100%"}}>
                                    <div className="uk-card-media-top">
                                        <div className="uk-card-media-top-image" style={{backgroundImage: "url(" + post.header + ")", backgroundSize: "cover", backgroundPosition: "center center", height: "350px"}} />
                                    </div>
                                    <div className="uk-card-body">
                                        <h4 className="uk-card-title" dangerouslySetInnerHTML={{__html: post.title}} />
                                        <p>{post.excerpt}</p>
                                        {post["show_disclaimer"] ?
                                            <div className="emissions" style={{marginBottom:"1em"}}>
                                                {disclaimers.map(disclaimer => {
                                                    return(
                                                        disclaimer.length > 0 ? <p style={{marginBottom:"0"}} dangerouslySetInnerHTML={{__html: disclaimer}} /> : <p></p>
                                                )
                                                })}
                                            </div>
                                        :   null
                                        }
                                    </div>
                                </div>
                                <div className="uk-card-footer" style={{backgroundColor: "#333"}}>
                                    <a className="uk-button uk-button-text" href={`${post.link}`} style={{color: "white"}}>Weiterlesen</a>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {total > posts.length &&
                    <div className="col-12 text-center button-bg-hover">
                        <button type="button" style={{letterSpacing: ".15em"}} className="btn btn-lg border border-dark mt-2 text-dark" onClick={loadNewPosts}>
                            WEITERE NEWS
                        </button>
                    </div>
                }
            </div>
        )
    } else {
        return null;
    }
};

export default BlogPosts;