import React, {useState, useEffect} from 'react';
import axios from "axios";

import "../vehicle-offer.css";

const VehicleOffer = (props) => {
    const [newVehicles, setNewVehicles] = useState([]);
    const [usedVehicles, setUsedVehicles] = useState([]);
    const [brandModel, setBrandModel] = useState("");

    useEffect(() => {
        getOffers(props.brand);
    }, [props.brand]);

    const getOffers = async (brand) => {
        let brandSerie = brand;

        if(brand.includes("KTM")) { brand = "KTM"; }
        else if(brand.includes("BMW")) { brand = "BMW"; }

        if(brandSerie === "BMW i") {
            setBrandModel("I");
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/new/angebotsFahrzeuge?limit=3&modelSeries=I-Reihe`)
                .then(result => {
                    setNewVehicles(result.data.items);
                })
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/used/angebotsFahrzeuge?limit=3&modelSeries=I-Reihe`)
                .then(result => {
                    setUsedVehicles(result.data.items);
                })
        }
        else if(brandSerie === "BMW M") {
            setBrandModel("M");
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/new/angebotsFahrzeuge?limit=3&modelSeries=M-Reihe`)
                .then(result => {
                    setNewVehicles(result.data.items);
                })
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/used/angebotsFahrzeuge?limit=3&modelSeries=M-Reihe`)
                .then(result => {
                    setUsedVehicles(result.data.items);
                })
        } else {
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/new/angebotsFahrzeuge?limit=3`)
                .then(result => {
                    setNewVehicles(result.data.items);
                })
            axios.get(`https://fahrzeugmarkt.auer-gruppe.de/api/fms/car/${brand}/used/angebotsFahrzeuge?limit=3`)
                .then(result => {
                    setUsedVehicles(result.data.items);
                })
        }
    }

    function getValue(vehicle, attributeName, fallback = '-') {
      if (vehicle == null || vehicle.emissionFuelConsumption == null) {
        return fallback;
      }
      const value = vehicle.emissionFuelConsumption[attributeName];
      return (value && value !== '0,00') ? value : fallback;
    }

    const getEmissionText = vehicle => {
        if(vehicle.emissionFuelConsumption && (vehicle.motorart === "Benzin" || vehicle.motorart === "Diesel")) {
              return (
                  <div className="emissions font-weight-light small">
                      <span>WLTP Energieverbrauch kombiniert: {getValue(vehicle, 'wltpKraftstoffverbrauchKombiniert')} l/100km; </span>
                      <span>WLTP CO2-Emissionen kombiniert: {getValue(vehicle, 'wltpCo2emissionenKombiniert')} g/km; </span>
                      <span>CO2-Klasse: {getValue(vehicle, 'wltpCo2KlasseKombiniert')}; </span>
                      <span>Leistung: {vehicle.power}; </span>
                      <span>Hubraum: {vehicle.cubicCapacity}; </span>
                      <span>Kraftstoff: {vehicle.motorart}; </span>
                  </div>
              );
        } else if (vehicle.emissionFuelConsumption && vehicle.motorart === "Elektromotor") {
            return (
                <div className="emissions font-weight-light small">
                    <span>WLTP Energieverbrauch kombiniert: {getValue(vehicle, 'wltpStromverbrauchKombiniert')} kWh/100 km; </span>
                    <span>WLTP CO2-Emissionen kombiniert: 0 g/km; </span>
                    <span>CO2-Klasse: A; </span>
                    <span>WLTP Elektrische Reichweite: {getValue(vehicle, 'wltpReichweiteElektrisch')} km; </span>
                    <span>Spitzenleistung: {vehicle.power}; </span>
                </div>
            );
        } else if (vehicle.emissionFuelConsumption && vehicle.motorart === "Hybrid") {
            return (
                <div className="emissions font-weight-light small">
                    <span>WLTP Energieverbrauch gewichtet kombiniert: {getValue(vehicle, 'wltpStromverbrauchKombiniertGewichtet')} kWh/100 km und {getValue(vehicle, 'wltpKraftstoffverbrauchKombiniertGewichtet')} l/100 km; </span>
                    <span>WLTP CO2-Emissionen gewichtet kombiniert: {getValue(vehicle, 'wltpCo2emissionenKombiniertGewichtet')} g/km; </span>
                    <span>CO2-Klassen: bei entladener Batterie {getValue(vehicle, 'wltpCo2KlasseKombiniertEntladung')}, gewichtet kombiniert {getValue(vehicle, 'wltpCo2KlasseKombiniert')}; </span>
                    <span>WLTP Kraftstoffverbrauch bei entladener Batterie kombiniert: {getValue(vehicle, 'wltpKraftstoffverbrauchKombiniertEntladung')} l/100 km; </span>
                    <span>WLTP Elektrische Reichweite (EAER): {getValue(vehicle, 'wltpReichweiteElektrisch')} km; </span>
                    <span>Systemleistung: {vehicle.power}; </span>
                </div>
            )
        }
    };

    const generateOfferContent = (vehicles) => {
        let content = [];

        vehicles.map(vehicle => {
            let shortRegistrationTime = false;

            if (vehicle.firstRegistration) {
                const [day, month, year] = vehicle.firstRegistration.split('.');
                const firstRegistrationDate = new Date(year, month - 1, day);

                const today = new Date();
                const nineMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 9, today.getDate());

                if (firstRegistrationDate >= nineMonthsAgo) {
                    shortRegistrationTime = true;
                }
            }

            if (vehicle.image) {
                let financeOptions = [];
                if (vehicle.financing) {
                    financeOptions = vehicle.financing.filter((financeFilter) => financeFilter.finanzierungsvariante == "2");

                    if (financeOptions.length === 0) {
                  financeOptions = vehicle.financing.filter((financeFilter) => financeFilter.finanzierungsvariante == "3");
                }
              }

                content.push(
                    <div className="col-12 col-sm-12 col-lg-4" style={{ marginBottom: 20 }}>
                        <div className={`vehicle-offer vehicle-offer-list-mode shadow`}>
                            <div>
                                <div className="title" style={{ textTransform: "none" }}>
                                    {vehicle.shortDescription}
                                </div>
                                <div
                                    className="img-fluid vehicle-offer-img"
                                    style={{ background: `url(${"https://fahrzeugmarkt.auer-gruppe.de/fm/images/" + vehicle.image}` }}
                                />
                            </div>
                            <div>
                                {financeOptions && financeOptions.length === 0 && <div className="pricing">
                                  <h2>{vehicle.price }</h2>
                                  <h3>{vehicle.reducedBy ? "Reduziert um " + vehicle.reducedBy : ""}</h3>
                                </div>}

                              {financeOptions.length > 0 && financeOptions.map(finance => {
                                switch(finance.finanzierungsvariante) {
                                  case "2":
                                    return <div className="flex p-2">
                                      <div className="flex-1 text-left">
                                        <div>Leasing</div>
                                        <div className="text-2xl"><span className="text-sm mr-2">ab</span>{finance.monthlyRate}<span className="text-sm ml-2">mtl.</span></div>
                                        <p className="text-sm">inkl. MwSt. • {finance.periodInMonths} Monate Laufzeit{finance.jahresKm && finance.jahresKm > 0 && <span> • {finance.jahresKm}km / Jahr</span>}</p>
                                      </div>
                                      <div className="flex-1 text-right">
                                        <div>Kaufpreis</div>
                                        <div className="text-2xl">{vehicle.price ? vehicle.price : ""}</div>
                                        {!!vehicle.reducedBy && <div className="text-sm font-bold text-red-700">Reduziert um {vehicle.reducedBy}</div>}
                                      </div>
                                    </div>

                                  case "3":
                                    return <div className="flex p-2">
                                      <div className="flex-1 text-left">
                                        <div>Finanzierung</div>
                                        <div className="text-2xl"><span className="text-sm mr-2">ab</span>{finance.monthlyRate}<span className="text-sm ml-2">mtl.</span></div>
                                        <p className="text-sm">inkl. MwSt. • {finance.periodInMonths} Monate Laufzeit{finance.jahresKm && finance.jahresKm > 0 && <span> • {finance.jahresKm}km / Jahr</span>}</p>
                                      </div>
                                      <div className="flex-1 text-right">
                                        <div>Kaufpreis</div>
                                        <div className="text-2xl">{vehicle.price ? vehicle.price : ""}</div>
                                        {!!vehicle.reducedBy && <div className="text-sm font-bold text-red-700">Reduziert um {vehicle.reducedBy}</div>}
                                      </div>
                                    </div>
                                }}
                              )}
                            </div>
                            <div>

                                { (vehicle.condition !== "Gebraucht" || parseInt(vehicle.mileage.replace(".", "")) <= 2500 || shortRegistrationTime) ? getEmissionText(vehicle) : null}
                                <div style={{ marginTop: 20 }} className="information">
                                    <a  href={`https://fahrzeugmarkt.auer-gruppe.de/fahrzeug/${vehicle.adId}`} target="_blank" className="button-line-hover">
                                        <button className="btn" type="button" style={{ width: "100%", height: "45px", backgroundColor: "#333", borderColor: "#333", color: "white", borderRadius: 0 }}>
                                            <div className="hover-btn">
                                                <div className="position-relative">
                                                    <i style={{ marginRight: 10 }} className="fas fa-external-link-alt"></i>
                                                    Fahrzeug anzeigen
                                                </div>
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })

        return content;
    };

     return (
        <div id="vehicle-list" className="mb-5 mt-5">
            {newVehicles.length > 0 &&
                <div style={{textAlign: "center"}}>
                    <header className="section-header mb-5 mt-5 text-center">
                        <h2>Unsere Top-Aktionsfahrzeuge neue Automobile</h2>
                    </header>
                    <div className="row">
                        {generateOfferContent(newVehicles)}
                    </div>
                    <a
                        style={{letterSpacing: ".15em"}}
                        className="text-dark button-link-vehicle button-bg-hover"
                        href={`/fahrzeugangebote/?condition=NEW&brand=${props.brand.includes("BMW") ? "BMW" : props.brand}${brandModel !== "" ? "&model=" + brandModel : ""}`} target="_blank"
                    >
                        <button type="button" className="btn btn-lg border border-dark mb-5 mt-2">
                            ALLE ANZEIGEN
                        </button>
                    </a>
                </div>
            }
            {usedVehicles.length > 0 &&
                <div style={{textAlign: "center"}}>
                    <header className="section-header mb-5 text-center">
                        <h2>Unsere Top-Aktionsfahrzeuge gebrauchte Automobile</h2>
                    </header>
                    <div className="row">
                        {generateOfferContent(usedVehicles)}
                    </div>
                    <a
                        style={{letterSpacing: ".15em"}}
                        className="text-dark button-link-vehicle button-bg-hover"
                        href={`/fahrzeugangebote/?condition=USED&brand=${props.brand.includes("BMW") ? "BMW" : props.brand}${brandModel !== "" ? "&model=" + brandModel : ""}`} target="_blank"
                    >
                        <button type="button" className="btn btn-lg border border-dark mt-2">
                            ALLE ANZEIGEN
                        </button>
                    </a>
                </div>
            }
        </div>
    );
};

export default VehicleOffer;
