import React, {useState, useEffect} from 'react';

const Fahrzeugmarkt = (props) => {
    const updateFahrzeugmarkt = async () => {
        let chosenBrand = props.brand;

        if(document.getElementById("input-brand") && props.brand) {
            document.getElementById("input-brand").value = chosenBrand.includes("KTM") ? "KTM" : chosenBrand;
            document.getElementById("input-brand").click();
        }

        if(document.getElementById("input-brand-color") && props.brandColor) {
            document.getElementById("input-brand-color").value = props.brandColor;
            document.getElementById("input-brand-color").click();
        }
    };

    return(
        <div onLoad={updateFahrzeugmarkt()} id={ 'fm-fastsearch-' + window.__UNIQUE_ID } style={{margin: "2em 0 1em 0"}} />
    );
};

export default Fahrzeugmarkt;
