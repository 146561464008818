import React, { Component, useState, useRef } from "react";
import axios from 'axios';

import BlogPosts from "./components/BlogPosts";
import CardLinks from "./components/CardLinks";
import InformationCards from "./components/InformationCards";
import Locations from "./components/Locations";
import VehicleOffer from "./components/VehicleOffer";
import BrandSlider from "./components/BrandSlider";
import HeaderSlider from "./components/HeaderSlider";
import Fahrzeugmarkt from "./components/Fahrzeugmarkt";

class BrandFrontpage extends React.Component {
	state = {
		index: 0,
		brands: [],
		locations: [],
		activeBrand: null,
		activeSliderIndex: 0,
		brandList: null,
		reload: false,
		loading: true,
		updateFahrzeugmarkt: false
	};
	myRef= null;

	async componentDidMount() {
		await axios.get("/wp-json/custom/v1/brands")
		.then(data => {
			this.setState({
				brands: data.data.brands,
				locations: data.data.locations,
			});
		});

		await this.setActiveBrand(window.__PRESELECTED_BRAND);
		await this.setBrandList();
		await this.setTitleNUrl(window.__PRESELECTED_BRAND, true);
	}

	async setTitleNUrl(chosenBrand, initial = false) {
		let title = "";

		chosenBrand = chosenBrand.replace(' ', '-');

		document.title = `${chosenBrand} Marke | Auer Gruppe`;
		if(!initial) { window.history.pushState('page2', 'Title', window.location.href.split('marken')[0] + "marken/" + chosenBrand.toLowerCase()); }
	}

	async setActiveBrand(chosenBrand, index, setIndex = false) {
		this.state.brands.map(brand => {
			if(brand.brand === chosenBrand) {
				if(setIndex) {
					this.setState({
						activeBrand: brand,
						updateFahrzeugmarkt: true,
						activeSliderIndex: index
					});
				}
				else {
					this.setState({
						activeBrand: brand,
						updateFahrzeugmarkt: true
					});
				}
				return;
			}
		});
	}

	changeBrandLogo = (logo) => {
		let logoListElement = document.getElementsByClassName("navigation-brand-logo")[0];
		logoListElement.innerHTML = `<img src="${logo}" />`;
	};
	changeNavFooterFont() {
		if(this.state.activeBrand.brand.includes("BMW")) {
			document.getElementById("masthead").style.fontFamily = "BMW";
			document.getElementsByClassName("elementor-location-footer")[0].style.fontFamily = "BMW";
		} else {
			document.getElementById("masthead").style.fontFamily = "Roboto";
			document.getElementsByClassName("elementor-location-footer")[0].style.fontFamily = "Roboto";
		}
	}

	onSlideChange = async (index) => {
		await this.setActiveBrand(this.state.brandList[index].name, index, true);
		this.setTitleNUrl(this.state.brandList[index].name);
		this.changeBrandLogo(this.state.brandList[index].image);
		this.changeNavFooterFont();
	};

	async setBrandList() {
		let activeSliderIndex = 0;

		{/* TODO remove dirty filtering if separate option available for brand slider to show/hide brands independent of the rest of the pages */}
		const brands = window.__BRANDS.filter((brand) => !["jaguar", "land-rover"].includes(brand.slug));

		await brands.map((brand, index) => {
			if(this.state.activeBrand && (brand.name === this.state.activeBrand.brand)) {
				activeSliderIndex = index;
				return;
			}
		});

		this.setState({
			brandList: brands,
			activeSliderIndex: activeSliderIndex,
			loading: false
		});
	}

	printHeader() {
		return(
			<div>
				<div className="show-desktop-brand-content">
					<div id="image-slider">
						{this.state.activeBrand ?
							<HeaderSlider
								myRef={this.state.myRef}
								brandColor={this.state.activeBrand.brandColor}
								textColor={this.state.activeBrand.brand === "Lotus" ? "black" : "white"}
								title={""}
								images={this.state.activeBrand.sliderImages}
							/>
						:
							<div className="d-flex align-items-center" style={{width:"100%", height: "600px", background: "#333333"}}>
								<div className="spinner-border" style={{margin: "0 auto", color: "white"}} role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						}
					</div>

					{this.state.brandList ?
						<div style={{backgroundColor: "#d6d6d6"}}>
							<BrandSlider showBrands={5} brandsList={this.state.brandList} onSlideChange={this.onSlideChange} activeSliderIndex={this.state.activeSliderIndex} />
						</div>
					:
						<div className="d-flex align-items-center" style={{width:"100%"}}>
							<div className="spinner-border" style={{margin: "0 auto", color: "white"}} role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					}
				</div>
				<div className="show-mobil-brand-content">
					<div style={{backgroundColor: "#d6d6d6"}}>
						{this.state.brandList && !this.state.loading ? <BrandSlider showBrands={3} brandsList={this.state.brandList} onSlideChange={this.onSlideChange} activeSliderIndex={this.state.activeSliderIndex} /> : "loading"}
					</div>
					<div id="image-slider">
						{this.state.activeBrand ?
							<HeaderSlider
								myRef={this.state.myRef}
								brandColor={this.state.activeBrand.brandColor}
								textColor={this.state.activeBrand.brand === "Lotus" ? "black" : "white"}
								title={""}
								images={this.state.activeBrand.sliderImages}
							/>
						:	null
						}
					</div>
				</div>
			</div>
		);
	}

	render(){
		let cards = [];
		let links = [];
		let length = 0;

		if(this.state.activeBrand) {
			cards = this.state.activeBrand.informationCards;
			links = this.state.activeBrand.linksCard;
		}

		return(
			<div className={this.state.activeBrand && this.state.activeBrand.brand.includes("BMW") ? "is-bmw" : "not-bmw"}>
				{this.printHeader()}
				<div style={{maxWidth: "1400px", margin: "3em auto", marginTop: "0"}}>
					<div className="container">
						{this.state.activeBrand ?
							this.state.activeBrand.disclaimer[0].split("[nl]").map(disclaimer => {
								if(disclaimer !== "") {
									return <span id="auer-dat-disclaimer" className="dat-disclaimer">{disclaimer}</span>
								} else {
									return <div style={{marginTop: "1em"}}></div>
								}
							})
						: ""
						}
						{this.state.activeBrand ?
							<div className="vehicle-offer-container">
								<div ref={(ref) => this.state.myRef = ref}>
									<VehicleOffer
										textColor={this.state.activeBrand.brand === "Lotus" ? "black" : "white"}
										brand={this.state.activeBrand.brand.includes("KTM") ? "KTM" : this.state.activeBrand.brand}
										color={this.state.activeBrand.brandColor}
									/>
								</div>
							</div>
						:
							null
						}
						<Fahrzeugmarkt
							brand={this.state.activeBrand && this.state.activeBrand.brand}
							brandColor={this.state.activeBrand && this.state.activeBrand.brandColor}
							update={this.state.updateFahrzeugmarkt}
						/>
						<header className="section-header mb-5 mt-5 text-center">
							<h2>Verfügbar an folgenden Standorten</h2>
						</header>
						<div className="elementor-section">
							{this.state.activeBrand ? <Locations brand={this.state.activeBrand && this.state.activeBrand.brand} locations={this.state.locations} brandLocations={this.state.activeBrand.locations} color={this.state.activeBrand.brandColor} /> : null}
						</div>

						{(cards.length > 0 || links.length > 0 && links[0] !== "") &&
							<div>
								<header className="section-header mb-5 mt-5 text-center">
									<h2>Mehr zu {this.state.activeBrand && this.state.activeBrand.brand}</h2>
								</header>
								<section className="elementor-section">
									<div className="row mb-3 mt-5">
										{cards.length > 0 ? <InformationCards hasLinks={links.length > 0} cards={cards} length={(cards.length + links.length)} color={this.state.activeBrand.brandColor} /> : null}
										{links.length > 0 && links[0] !== "" ?
											<div
												className={`col-12 col-sm-12 
												${(cards.length + links.length) < 2 ? "col-md-8 offset-md-2" : "col-md-6"} 
												${(cards.length + links.length) === 4 ? "col-lg-6" : (cards.length + links.length) > 4 ? "col-lg-4" : ""}
											`}
											>
												<CardLinks color={this.state.activeBrand.brandColor} links={links} />
											</div>
											:	null
										}
									</div>
								</section>
							</div>
						}
					</div>
				</div>
				<section
					className="container-fluid"
					style={{backgroundColor: "rgba(122, 122, 122, 0.30980392156862746)", paddingBottom: "3em", paddingTop: "2em"}}
				>
					<div className="container vehicle-offer-container">
						<header className="section-header mb-5 mt-2 text-center">
							<h2>News</h2>
						</header>
						{this.state.activeBrand ? <BlogPosts brand={this.state.activeBrand.brand} /> : null}
					</div>
				</section>
			</div>
		);
	};
}

export default BrandFrontpage;
