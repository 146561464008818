import React from 'react';

const CardLinks = (props) => {
    if(props.links.length > 0) {
        return (
            <div className="auer-card-link">
                <div style={{position: "relative"}}>
                    <div className="links-card">
                        <ul>
                        {
                            props.links.map(link => {
                                return(
                                    <li>
                                    <a href={`${link["link-url"]}`} target={link["link-is-extern"] === "true" ? "_blank" : ""} rel="noopener">{link["link-title"]}</a>
                                    </li>
                            )
                            })
                        }
                        </ul>
                    </div>
                    <div className="info-card-title">
                        Links
                    </div>
                </div>
            </div>
        )
    }
    else{
        return "";
    }
};

export default CardLinks;