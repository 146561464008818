import React, {useState, useEffect} from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeaderSlider = (props) => {
    const [images, setImages] = useState([{}]);

    useEffect(() => {
        setImages(props.images);
    }, [props.images]);

    const printHeaderImage = (image) => {
        return (
            <div>
                <div className="show-mobil-brand-content">
                    {image["img-link"] ?
                        <a href={image["img-link"]} target={image["is-extern"] === "true" ? "_blank" : ""}>
                            <div className="image-slider" style={{backgroundImage: `url(${image["mobil-src"] ? image["mobil-src"] : image["src"]})`, backgroundPosition: image["img-position"]}}/>
                        </a>
                    :
                        <div className="image-slider" style={{backgroundImage: `url(${image["mobil-src"] ? image["mobil-src"] : image["src"]})`, backgroundPosition: image["img-position"]}}/>
                    }
                </div>
                <div className="show-desktop-brand-content">
                    {image["img-link"] ?
                        <a href={image["img-link"]} target={image["is-extern"] === "true" ? "_blank" : ""}>
                            <div className="image-slider" style={{backgroundImage: `url(${image["src"]})`, backgroundPosition: image["img-position"]}}/>
                        </a>
                    :
                        <div className="image-slider" style={{backgroundImage: `url(${image["src"]})`, backgroundPosition: image["img-position"]}}/>
                    }
                </div>
            </div>
        )
    };
    const printSlideContent = (image, consumptionText) => {
        if(image["text-content"] && !Array.isArray(image["text-content"])) {
            if(image["text-content"].includes("[nl]")) {
                image["text-content"] = image["text-content"].split("[nl]");
            } else {
                image["text-content"] = [image["text-content"]];
            }
        }
        if(image["header"] !== "" || image["sec-header"] !== "") {
            return(
                <div>
                    <div className="row text-container" style={{clear: window.__BRAND_VIEW ? "none" : "both"}}>
                        <div className="col-12 col-sm-12">
                            <h1>{image["header"]}</h1>
                            <h2>{image["sec-header"]}</h2>
                        </div>
                        <div className="col-12 col-sm-12" style={{marginBottom: "1rem"}}>
                            {image["text-content"] && image["text-content"] !== "" &&
                                image["text-content"].map(text => {
                                    return (
                                        <p
                                            style={{marginBottom: text !== "" ? "0" : ".5rem", marginTop: "0", fontSize: image["text-size"] + "px", color: image["text-color"], background: image["text-bg"], width: "100%", fontWeight: "100", padding: image["text-bg"] !== "" ? ".1em 1em" : "0"}}
                                            className={`${image["text-deco"] === "kursiv" ? "font-italic" : image["text-deco"] === "bold" ? "font-weight-bold" : ""} ` +
                                            `${image["text-up-low"] === "lower" ? "text-lowercase" : image["text-up-low"] === "upper" ? "text-uppercase" : ""}`}
                                        >
                                            {`${text}`}
                                        </p>
                                    )
                                })
                            }
                            <div className="row offer-n-link">
                                {image["offer-price-title"] !== "" || image["offer-price-content"] !== "" ?
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-1">
                                        {image["offer-price-title"] !== "" ? <p className="offer-price-title" style={{fontSize: image["offer-price-font-size"] + "px"}}>{image["offer-price-title"]}</p> : "" }
                                        {image["offer-price-content"] !== "" ? <p style={{fontSize: image["offer-price-content-font-size"] + "px", color: image["offer-price-content-color"]}}>{image["offer-price-content"]}</p> : "" }
                                    </div>
                                :   null
                                }
                                {image["monthly-payment-title"] !== "" || image["monthly-payment-content"] !== "" ?
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-1">
                                        {image["monthly-payment-title"] !== "" ? <p className="monthly-payment-title" style={{fontSize: image["monthly-payment-font-size"] + "px"}}>{image["monthly-payment-title"]}</p> : "" }
                                        {image["monthly-payment-content"] !== "" ? <p style={{fontSize: image["monthly-payment-content-font-size"] + "px", color: image["monthly-payment-content-color"]}}>{image["monthly-payment-content"]}</p> : "" }
                                    </div>
                                :   null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row offer-n-link-buttons text-container">
                        <div className="col-12 col-sm-12">
                            <div className="row offer-n-link">
                                {image["offer-link-title"] !== "" ?
                                    <div className={`col-12 col-sm-6 col-md-6 col-lg-6 ${image["btn-text"] === "" ? "mb-3" : "mb-1" }`}>
                                        <a
                                            className="btn btn-secondary w-100 rounded-0"
                                            target={image["offer-link-is-extern"] === "true" ? "_blank" : ""}
                                            href={image["offer-link"] !== "" ? image["offer-link"] : null}
                                            style={{color: image["btn-text-color"], background: image["btn-bg"], cursor: image["offer-link"] === "" ? "default" : "pointer"}}
                                        >
                                            {image["offer-link-title"]}
                                        </a>
                                    </div>
                                :   null
                                }
                                {image["offer-video-title"] !== "" ?
                                    <div className={`col-12 col-sm-6 col-md-6 col-lg-6 ${image["btn-text"] === "" ? "mb-3" : "mb-1" }`}>
                                        <a
                                            className="btn btn-secondary w-100 rounded-0"
                                            target={image["offer-video-is-extern"] === "true" ? "_blank" : ""}
                                            href={image["offer-video"] !== "" ? image["offer-video"] : null}
                                            style={{color: image["btn-text-color"], background: image["btn-bg"], cursor: image["offer-video"] === "" ? "default" : "pointer"}}
                                        >
                                            {image["offer-video-title"]}
                                        </a>
                                    </div>
                                :   null
                                }
                            </div>
                            <div className="row offer-n-link">
                                {image["btn-text"] !== "" && image["img-link"] ?
                                    <div className="col-12 col-sm-6 col-md-6 col-xl-6 mb-3">
                                        <a
                                            className="btn btn-secondary w-100 rounded-0"
                                            style={{background: image["btn-bg"], color: image["btn-text-color"]}}
                                            href={image["img-link"]} target={image["is-extern"] === "true" ? "_blank" : ""}
                                        >
                                            {image["btn-text"]}
                                        </a>
                                    </div>
                                :   null
                                }
                            </div>
                        </div>
                        <div className="col-12 col-sm-12" style={{alignSelf: "flex-end"}}>
                            <div className="col-12 col-sm-12 consumption-text">
                                {consumptionText !== "" ? consumptionText.map((text,index) =>
                                    {
                                        return <p style={{fontSize: "15px", marginBottom: (index+1) === consumptionText.length ? "0" : "1em"}}>{text}</p>
                                    })
                                :   null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else { return null; }
    };

    const printHeaderSlider = () => {
        let settings = {
            cssEase: "linear",
            dots: true,
            infinite: true,
            lazyLoad: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
        };
        let slider;

        if(images.length > 0) {
            return (
                <div>
                    <Slider ref={c => (slider = c)} {...settings}>
                        {images.map((image, index) => {
                            let consumptionText = "";

                            if(image["src"] === "" || image["src"] === false) { return null; }
                            if(image["show-slider"] === "false" || image["show-slider"] === false) { return null; }
                            if (image["consumption-text"]) {
                                consumptionText = image["consumption-text"].split("[nl]");
                            }
                            return(
                                <div className="slider-content">
                                    {images.length > 1 ?
                                        <i
                                            style={{ cursor: "pointer", fontSize: "2.5em", position: "absolute", left: "0", zIndex: "999", color: image["left-arrow-color"] }}
                                            className="fas fa-chevron-circle-left left-arrow-header-slider"
                                            onClick={() => slider.slickPrev()}
                                        />
                                    :   null
                                    }
                                    <div className="show-desktop-brand-content">
                                        {image['header'] !== "" ?
                                            <div className="d-flex slider-box-container">
                                                <div className="slider-content-box">
                                                    <img className="auer-watermark" src={window.__WATERMARK} />
                                                    {printSlideContent(image, consumptionText)}
                                                </div>
                                                <div class="slider-image-container">
                                                    {printHeaderImage(image)}
                                                </div>
                                            </div>
                                        :
                                            printHeaderImage(image)
                                        }
                                    </div>
                                    <div className="show-mobil-brand-content">
                                        <div class="slider-image-container">
                                            {printHeaderImage(image)}
                                        </div>
                                        {image['header'] !== "" ?
                                            <div className="slider-content-box">
                                                <img className="auer-watermark" src={window.__WATERMARK} />
                                                {printSlideContent(image, consumptionText)}
                                            </div>
                                        :
                                            null
                                        }
                                    </div>
                                    {images.length > 1 ?
                                        <i
                                            style={{ cursor: "pointer", fontSize: "2.5em", position: "absolute", right: "0", zIndex: "999", color: image["right-arrow-color"] }}
                                            className="fas fa-chevron-circle-right right-arrow-header-slider"
                                            onClick={() => slider.slickNext()}
                                        />
                                    :   null
                                    }
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            )
        } else { return null }
    };

    return(
        printHeaderSlider()
    );
};

export default HeaderSlider;