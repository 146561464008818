import React, {useState, useEffect} from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandsSlider = (props) => {
    const [brands, setBrands] = useState([{}]);
    let slider = null;

    useEffect(() => {
        const indexes = [];
        setBrands(props.brandsList);
    }, [props.brandsList]);

    // workaround for slick slider jumping between last and first slide
    // https://github.com/kenwheeler/slick/issues/2711
    const pauseAnimation = function() {
      var slideTrack = document.querySelector('#brands-slider .slick-track' );
      slideTrack.classList.add('no-animation')
      setTimeout(function() {
        slideTrack.classList.remove('no-animation')
      }, 50)
    }

    const printBrandSlider = () => {
        let settings = {
            cssEase: "linear",
            infinite: true,
            speed: 250,
            centerMode: true,
            className: "center",
            initialSlide: props.activeSliderIndex,
            slidesToShow: props.showBrands,
            slidesToScroll: 1,
            lazyLoad: true,
            afterChange: function(current) {
              pauseAnimation();
              props.onSlideChange(current)
            },
            beforeChange: function(current, next) {
              if(Math.abs(current - next) > 2) {
                pauseAnimation();
              }
          },
        };
        let length = brands.length;

        if(length > 0) {
            return(
                <div id="brands-slider">
                    <Slider ref={sliderEle => (slider = sliderEle)} {...settings}>
                        {brands.map((brand,index) => {
                            return(
                                <div
                                    className={`brand-offer-box`}
                                    onClick={() => slider.slickGoTo(index)}
                                >
                                    <img src={brand.image} />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            )
        } else {
            return "";
        }

    }

    return(
        printBrandSlider()
    );
};

export default BrandsSlider;
