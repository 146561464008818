import React from 'react';

const InformationCards = (props) => {
    if(props.cards.length > 0) {
        return (
            props.cards.map(card => {
                return (
                    <div
                        className={
                            `col-12 col-sm-12 
                            ${props.cards.length < 2 ? "col-md-8 offset-md-2" : "col-md-6"} 
                            ${props.cards.length === 3 && props.hasLinks || props.cards.length === 4 ? "col-lg-6" : props.cards.length > 4 ? "col-lg-4" : ""} 
                            mb-3`
                        }
                    >
                        <a className="auer-cards-link" href={`${card["card-link"]}`} target={card["card-is-extern"] === "true" ? "_blank" : ""}>
                            <div style={{position: "relative"}}>
                                <div
                                    className="show-mobil info-card-img"
                                    style={{backgroundImage: "url(" + card["mobil-src"] + ")"}}
                                />
                                <div
                                    className="show-desktop info-card-img"
                                    style={{backgroundImage: "url(" + card.src + ")"}}
                                />
                                <div className="info-card-title">
                                    {card["card-title"]}
                                </div>
                            </div>
                        </a>
                    </div>
                )
            })
        )
    } else {
        return "";
    }
};

export default InformationCards;