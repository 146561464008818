import React from 'react';

const Locations = (props) => {
    if(props.locations.length > 0 && props.brandLocations.length > 0) {
        return(
            <div className="row location-row mb-3">
                {props.brandLocations.map(brandLocation => {
                    return(
                        props.locations.map(location => {
                            if(brandLocation == location.id) {
                                return(
                                    <a
                                        className={`button-bg-hover mb-2 col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 location-col`}
                                        href={(props.brand === "BMW" && (location.title !== "Singen" && location.title !== "Bad Saulgau")) ? `${location.link}?brand=${props.brand.split(' ').join('-')}` : location.link}
                                    >
                                        <button
                                            type="button"
                                            style={{width: "100%", color: "#333", fontSize: "25px"}}
                                            className="btn btn-lg border border-dark mt-2 location-btn"
                                        >
                                            {location.title}
                                        </button>
                                    </a>
                                )
                            }
                        })
                    )
                })}
            </div>
        )
    }
};

export default Locations;